import { useParams } from "react-router-dom"


function Skill() {

    const { language } = useParams()
    // Return Page Dev component with first Section and generate Features presentation
    return (
        <div className="containerSkill">
             {language === "ENG" ? <h2 className="titleSkill">Skills</h2> : <h2 className="titleSkill">Compétences</h2>}

            <div className="blocSkill">
                {language === "ENG" ? <h3>System</h3> : <h3>Système</h3>}

                <p className="textSkill">
                    <i className="fa-solid fa-hashtag"></i>&nbsp;
                    Windows (XP/VISTA/7/10) <br></br><br></br>
                    <i className="fa-solid fa-hashtag"></i>&nbsp;
                    Windows Server (2008/2012/2016/2019)<br></br><br></br>
                    <i className="fa-solid fa-hashtag"></i>&nbsp;
                    Linux (Debian 7 / 10 / 11 /Ubuntu / Kali)<br></br><br></br>
                    <i className="fa-solid fa-hashtag"></i>&nbsp;
                    Hypervisor (Hyper-V /Vmware Workstation)<br></br><br></br>
                    <i className="fa-solid fa-hashtag"></i>&nbsp;
                    Windows Infra Services (DHCP/DNS/AD/IIS/WDS/WSUS)<br></br><br></br>
                    <i className="fa-solid fa-hashtag"></i>&nbsp;
                    Linux Infra Services (LAMP/Web/MAIL(Postfix)/VOIP(Asterisk)/FTP/Firewall(Pfsense))
                </p>
            </div>
            <div className="blocSkill">
                {language === "ENG" ? <h3>Development Language</h3> : <h3>Langage de dévelopement</h3>}

                <p className="textSkill">
                    <i className="fa-solid fa-hashtag"></i>
                    &nbsp;
                    Powershell <br></br><br></br>
                    <i className="fa-solid fa-hashtag"></i>
                    &nbsp;
                    Bash <br></br><br></br>
                    <i className="fa-solid fa-hashtag"></i>
                    &nbsp;
                    Javascript <br></br><br></br>
                    <i className="fa-solid fa-hashtag"></i>
                    &nbsp;
                    HTML5/CSS <br></br><br></br>
                    <i className="fa-solid fa-hashtag"></i>
                    &nbsp;
                    Solidity (REMIX IDE)<br></br><br></br>

                </p>
            </div>
            <div className="blocSkill">
                {language === "ENG" ? <h3>Frameworks and Library</h3> : <h3>Frameworks et library</h3>}

                <p className="textSkill">
                    <i className="fa-solid fa-hashtag"></i>
                    &nbsp;
                    React <br></br><br></br>
                    <i className="fa-solid fa-hashtag"></i>
                    &nbsp;
                    Redux <br></br><br></br>
                    <i className="fa-solid fa-hashtag"></i>
                    &nbsp;
                    Styled-Components <br></br><br></br>
                    <i className="fa-solid fa-hashtag"></i>
                    &nbsp;
                    Phaser.io <br></br><br></br>
                    <i className="fa-solid fa-hashtag"></i>
                    &nbsp;
                    Three.js <br></br><br></br>
                    <i className="fa-solid fa-hashtag"></i>
                    &nbsp;
                    Ether.js <br></br><br></br>
                    <i className="fa-solid fa-hashtag"></i>
                    &nbsp;
                    Jest <br></br><br></br>
                </p>
            </div>
        </div>

    )


}

export default Skill

