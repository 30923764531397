import { Navigate, useNavigate } from 'react-router-dom';

import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import Home from '../page/dev'
import Layout from '../component/layout'
import Choice from '../page/choice'
import Recruit from '../page/recruiter'
import { AnimatePresence } from 'framer-motion'
import { useEffect } from 'react';




// Generate path for route function

const router = createBrowserRouter([
    {
        path: "/",
        element: <Navigate to={'/ENG'} replace />
    },
    {
        path: '/:language',
        element: <Layout />,
        children: [
            {
                path: '',
                element: <Choice />,
            },
            {
                path: 'dev',
                element: <Home />,
            },
            {
                path: 'recruiter',
                element: <Recruit />,
            },

        ]
    }
])



/**
* Create route for render the appropriate UI
* @return { ReactElement }
*/

function Route() {
    return (

        <RouterProvider router={router}>
        </RouterProvider>
    )
}

export default Route