
import styled from 'styled-components'
import Input from './input'
import { Link, useParams } from 'react-router-dom'


const TerminalContainer = styled.div`
    background-color: rgb(48, 10, 36);
    width: 1200px;
    height:  ${props => (props.background ? "700px" : "0px")};
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 100px;
    overflow: hidden;
    overflow-y: auto;
    z-index: 1200; 
    position: relative;
    &::-webkit-scrollbar {
        width: 0px;
    };
    box-shadow: 10px 10px 40px rgba(0,0,0, 0.6);
    @media (max-width: 1200px) {
        width: 900px;
      }
    @media (max-width: 900px) {
        width: 100%;
      }
      @media (max-width: 600px) {
        height:  ${props => (props.background ? "800px" : "0px")};
    }
    
    .headerTerminal {
        width: ${props => (props.background ? "1200px" : '250px')};
        height: 30px;
        background-color: rgb(84, 82, 74);
        top: ${props => (props.background ? "-20px" : '87.5vh')};
        border-radius: 5px 5px 0 0;
        position: fixed;
        @media (max-width: 1200px) {
            width: ${props => (props.background ? "900px" : '250px')};
          }
        @media (max-width: 900px) {
            width: ${props => (props.background ? "100%" : '250px')};
          }
      
    }
    .icon1 {
        font-size: 20px;
        margin: 6px;
        color: rgb(232, 17, 35, 0.8);
    }
    .icon2 {
        color: rgb(211,211,211, 0.6);
        font-size: 20px;
        margin: 3px;
    }
    .user {
        position: relative;
        top: -41px;
        color: white;
        font-weight: bold;
        margin-left: 70px
    }
    .presentationTerminal {
        color: rgb(211,211,211);;
        display: flex;
        flex-direction: column;
        width: 100%;
        @media (max-width: 600px) {
            margin-top: 50px;
          }
    }
    .titleTerminal {
        font-size: 18px;
        font-weight: bold;
        @media (max-width: 900px) {
            font-size: 15px;
           }
         
    }
    .titleRecrut {
        margin-left: -40px;
        text-align: center;
        font-size: 20px;
    }
    .ascii {
        color: rgb(255,255,0, 0.7);
        margin: auto;
        margin-top: 0;
        margin-bottom: 20px;
        @media (max-width: 900px) {
            margin: auto;
            margin-bottom: 30px;
        }
        @media (max-width: 600px) {
            font-size: 10px;
        }
    }
    .containerPresentation {
        margin-left: 40px;
        @media (max-width: 600px) {
            margin-left:20px;
        }
    }
    .presentation { 
        margin-top: 0;
    }
    
`



function Terminal({ setClick, background }) {


    const handleClick = () => {
        setClick(prev => !prev)
    }

    const { language } = useParams()


    // Return Page Terminal component with first Section and generate Features presentation
    return (
        <TerminalContainer background={background}>
            <div className='headerTerminal'>
                <Link to={`/${language}`} ><i className="fa-solid fa-circle-xmark icon1" ></i></Link><i className="fa-solid fa-circle-minus icon2" onClick={handleClick}></i><p className='user'>hamham@hamham:~</p>
            </div>
            <div className='presentationTerminal'>
                <pre className='ascii'>
                    {`
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
@@    @@@@    @@@          #@@    @@@@@@@@    @@@@@@@@@@        *@@@@@
@@    @@@@    @@@    @@@@@@@@@    @@@@@@@@    @@@@@@@@    %@@@    (@@@
@@            @@@          @@@    @@@@@@@@    @@@@@@@%   ,@@@@@    @@@
@@    @@@@    @@@    @@@@@@@@@    @@@@@@@@    @@@@@@@@    @@@@@    @@@
@@    @@@@    @@@           @@          @@          @@@           @@@@
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@` }
                </pre>

                {
                    language === 'ENG' ?
                        <div className='containerPresentation'>
                            <p className='presentation'>It's my new web CV, welcome!</p>
                            <p>--  help to get the commands</p>
                            <p className='titleTerminal'>Javascript developer (react/nodejs) / passionate about blockchain and decentralization (solidity/orbitdb)</p>
                        </div>
                        :
                        <div className='containerPresentation'>
                            <p className='presentation'>C'est mon nouveau web CV!</p>
                            <p>--  saisir help pour voir les différentes commande</p>
                            <p className='titleTerminal'>Developpeur Javascript (react/nodejs) / passionné par la blockchain et la decentralisation (solidity/orbitdb)</p>
                        </div>
                }



            </div>
            <Input />

        </TerminalContainer>
    )


}

export default Terminal
