
import { useState } from 'react';
import { motion } from "framer-motion"
import Navigateur from '../component/navigateur';







function Recruit() {

    // Return Page Recruit component with first Section and generate Features presentation
    return (

        <motion.div
            initial={{ x: '50vh' }}
            animate={{
                    x: '0',

            }}
            transition={{ duration: 2 }}
        >
            <Navigateur />
        </motion.div>

    )


}

export default Recruit