import { Link, useParams } from "react-router-dom";



function Project() {

    const { language } = useParams()
    // Return Page Dev component with first Section and generate Features presentation
    return (

        <div className="containerProject">
            {language === "ENG" ? <h2>Projects</h2> : <h2>Projets</h2>}

            <div className="containerAll">
                <div className="BlocProject">
                    <h3><i className="fa-solid fa-bug"></i>&nbsp;Hacking</h3>
                    <Link target="_blank" className="links" to='https://github.com/Sharkoux/Hacking-for-newbies/blob/main/Backdoor%20rubber-ducky%20.ps1'>  {language === "ENG" ? "Backdoor for Rubber Ducky in Raspberry Zero" : "Backdoor pour Rubber Ducky sur Raspberry Zero"}</Link><br></br>
                    <Link target="_blank" className="links" to='https://github.com/Sharkoux/Hacking-for-newbies/blob/main/Schro.txt'> {language === "ENG" ? "Wifi Bash Hacking Automation Project" : "Projet d'automatisation de hack wifi en bash (Pause)"}</Link><br></br>
                </div>


                <div className="BlocProject">
                    <h3><i className="fa-brands fa-windows"></i>&nbsp; {language === "ENG" ? "Basic Admin Systems" : "Administration Systèmes de base"}</h3>
                    <Link target="_blank" className="links" to='https://github.com/Sharkoux/Script-d-administration-syst-me/blob/main/Installation%20VM%20multi%20ISO.txt'>{language === "ENG" ? "Install VM Multi ISO" : "Script d'installation de VM avec plusieurs choix de fichier iso"} </Link><br></br>
                    <Link target="_blank" className="links" to='https://github.com/Sharkoux/Script-d-administration-syst-me/blob/main/Installation-AD-DNS-Foret.txt'>{language === "ENG" ? "Install AD-DNS-FOREST" : "Script d'installation d'AD/DNS/FORET"}</Link><br></br>
                    <Link target="_blank" className="links" to='https://github.com/Sharkoux/Script-d-administration-syst-me/blob/main/Installation-acc%C3%A8s-powershell-web-access.txt'>{language === "ENG" ? "Powershell Web Access" : "Script d'initialisation Powershell web access"}</Link><br></br>
                    <Link target="_blank" className="links" to='https://github.com/Sharkoux/Script-d-administration-syst-me/blob/main/Suppression-compte-inactif.txt'>{language === "ENG" ? "Deleted inactive account" : "Script de suppression de compte inactif"} </Link><br></br>
                    <Link target="_blank" className="links" to='https://github.com/Sharkoux/Script-d-administration-syst-me/blob/main/modification-mot-de-passe-compte-ad.txt'>{language === "ENG" ? " Password AD" : "Script de modification de password dans l'AD"}</Link>
                </div>


                <div className="BlocProject ">
                    <h3><i className="fa-brands fa-linux"></i>&nbsp;{language === "ENG" ? "Basic Linux script" : "Administration Linux de base"} </h3>
                    <Link target="_blank" className="links" to='https://github.com/Sharkoux/Script-d-administration-syst-me/blob/main/Script.sh'>{language === "ENG" ? "Auto install Wordpress (PAUSE)" : "Script d'automatisation d'un site wordpress (Pause)"}</Link><br></br>
                    <Link target="_blank" className="links" to='https://github.com/Sharkoux/Script-d-administration-syst-me/blob/main/utilisateur2.sh'>{language === "ENG" ? "New User" : "Script simple de création utilisateur linux"}</Link><br></br>
                    <Link target="_blank" className="links" to='https://github.com/Sharkoux/Hacking-for-newbies/blob/main/firewall.sh'>{language === "ENG" ? "Simple Linux Firewall Script" : "Script simple de mise en place d'un firewall"}</Link>
                </div>


                <div className="BlocProject ">
                    <h3><i className="fa-brands fa-ethereum"></i>&nbsp;{language === "ENG" ? "Project crypto" : "Projets crypto"}  </h3>
                    <Link target="_blank" className="links" to='https://github.com/Sharkoux/Projet-HamHam'>{language === "ENG" ? `Project NFT "Hamham" ` : "Projets NFT HAMHAM"}</Link><br></br>
                    <Link target="_blank" className="links" to='https://github.com/Sharkoux/Script-Powershell-en-vrac/blob/main/cryptovaleur.ps1'>{language === "ENG" ? "Api Value crypto (1)" : "Recuperation valeur d'unbe crypto via API (powershell)"}</Link><br></br>
                    <Link target="_blank" className="links" to='https://github.com/Sharkoux/Powershell-Script-little-script-binance-new-listing/blob/main/project'>{language === "ENG" ? "Api interact Binance new Listing" : "Script d'interaction avec place de marché Binance (nouveau listing token)"}</Link><br></br>
                    <Link target="_blank" className="links" to='https://github.com/Sharkoux/Experimentation-PhaserGame-Moralis.io-en-pause-'>{language === "ENG" ? " Experimentation : Connect blockchain auth to 2d game" : "Experimentation: Utiliser une authentification via un wallet blockchain vers un jeu 2d"}</Link>
                </div>

                <div className="BlocProject ">
                    <h3><i className="fa-solid fa-gamepad"></i>&nbsp; World of Warcraft API</h3>
                    <Link target="_blank" className="links" to='https://github.com/Sharkoux/Script-Powershell-en-vrac/blob/main/World%20of%20warcraft'>{language === "ENG" ? "Application to retrieve the value of an item in the World of Warcraft auction house in real-time with Powershell (2019)" : "Application pour récupérer la valeur d'un item dans l'hotel de vente d'un serveur de World of Warcraft en temps réel (powershell)"}</Link><br></br>
                </div>

            </div>
            <div className="BlocProject front">
                <h3><i className="fa-brands fa-figma"></i>&nbsp;{language === "ENG" ? " Front-End Projects" : "Projet Front-end"} </h3>
                <Link target="_blank" className="links" to='https://sharkoux.github.io/project-1/'>{language === "ENG" ? "Integrate website html/css" : "Mise en place d'un site web depuis maquette (html/css)"}</Link><br></br>
                <Link target="_blank" className="links" to='https://github.com/Sharkoux/P3'>{language === "ENG" ? "Website with html/sass" : "Mise en place d'un site web depuis maquette (html/sass)"}</Link><br></br>
                <Link target="_blank" className="links" to='https://sharkoux.github.io/GameOn-website-FR/starterOnly/'>{language === "ENG" ? "Landing page / modal with javascript" : "Mise en place d'un landing page avec Javascript"}</Link><br></br>
                <Link target="_blank" className="links" to='https://github.com/Sharkoux/Front-End-Fisheye'>{language === "ENG" ? "Accessible Website (with NVDA page reader)" : "Mise en place d'un site web accessible (avec un lecteur de site pour personne en situation de handicap visuel)"}</Link><br></br><br></br>
                <p>{language === "ENG" ? "Website Les petits plats algo Javascript:" : "Projet site web Les petits plats (entrainement alogo javascript): "}</p>
                <Link target="_blank" className="links" to='https://github.com/Sharkoux/Projet-7/tree/Boucle-Native'>{language === "ENG" ? "Native Loop" : "Version boucle native"}</Link><br></br>
                <Link target="_blank" className="links" to='https://github.com/Sharkoux/Projet-7/tree/Programmation-fonctionnelle'>{language === "ENG" ? "Functional Programming" : "Version programmation fonctionnel"}</Link><br></br><br></br>

                <Link target="_blank" className="links" to='https://github.com/Sharkoux/Projet--D-buggez-et-testez-un-SaaS-RH'>{language === "ENG" ? "Debug and test a SaaS HR tool, learn Jest" : "Tester et débuguer un outil Saas RH, apprentissage de Jest"}</Link><br></br><br></br>
                <Link target="_blank" className="links" to='https://github.com/Sharkoux/kasa'>{language === "ENG" ? "Develop a web application with React and React Router" : "Developé une application web avec React et React Router"}</Link><br></br><br></br>
                <Link target="_blank" className="links" to='https://github.com/Sharkoux/Projet-12'>{language === "ENG" ? "Build an analytics dashboard with React" : "Construire un tableau de bord analytique avec React"}</Link><br></br><br></br>
                <Link target="_blank" className="links" to='https://github.com/Sharkoux/Projet-13'>{language === "ENG" ? "Use an API for a bank user account with React, learn Redux" : "Utiliser une API pour un compte bancaire avec React, apprentissage de Redux "}</Link><br></br><br></br>


            </div>

        </div>
    )


}

export default Project


