import { AnimatePresence } from 'framer-motion';
import React, { useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { createGlobalStyle } from 'styled-components';
import Language from './language';


const GlobalStyle = createGlobalStyle`
    body {
        padding: 0;
        background-image: linear-gradient(to right, rgb(139,0,0), rgb(30,144,255));
        font-family: 'Roboto', sans-serif;
    }
    .links {
        text-decoration: none;
        color: black;
    }
`



/* Use Layout component with Header and Footer */
const Layout = ({ children }) => {
    const [language, setLanguage] = useState("ENG")

    const navigate = useNavigate()

    const handleLanguageChange = (newLanguage) => {
        navigate(`/${newLanguage}`);
        setLanguage(newLanguage);
    }

    return (
        <React.Fragment>
            <GlobalStyle />
            <Language onLanguageChange={handleLanguageChange} language={language}/>
            <main>
                {children} <Outlet />
            </main>
        </React.Fragment>
    )
}

export default Layout
