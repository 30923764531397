import { useActionData, useParams } from "react-router-dom"


function Experience() {

    const { language } = useParams()

    // Return Page Dev component with first Section and generate Features presentation
    return (
        <div className="containerExperience">
            <h2>Experience</h2>
            <div className="containerMulti">
                {language === "ENG" ?
                    <>
                        <div className="BlocExperience">
                            <h3>2019</h3>
                            <p>System Technician end-of-training internship, User Support - GROUPE HOSPITALIER PAUL GUIRAUD - PARIS</p>
                            <p>
                                Task: <br></br><br></br>
                                User Ticket Management (ISILOG) <br></br><br></br>
                                PC and PRINTER SETUP <br></br><br></br>
                                Windows Administration Task, including creating AD/Exchange accounts
                            </p>
                        </div>
                        <div className="BlocExperience">
                            <h3>2019/2020</h3>
                            <p>Systems Administrator and Security Engineer Apprentice, Option Cybersecurity - THALES SERVICE - PARIS</p>
                            <p>
                                Task: <br></br><br></br>
                                Study of SCCM installation in a cybersecurity testing environment within the LAB (Thales cybersecurity experimentation space)<br></br><br></br>
                                Windows Administration Task, including server installation with PXE/DHCP/MDT/ADK services <br></br><br></br>
                            </p>
                        </div>
                        <div className="BlocExperience">
                            <h3>2020/2022</h3>
                            <p>Support System Technician, Level 2 / VIP - DCS EASYWARE - AGIRC-ARRCO Client</p>
                            <p>
                                Task: <br></br><br></br>
                                Large-scale Windows PC master<br></br><br></br>
                                Participation in the Windows 10 migration project (computer park of 1600 users) <br></br><br></br>
                                User Incident Management (GEDI/ITOP)<br></br><br></br>
                                IT asset management (EasyVista)<br></br><br></br>
                                Software Incident Management (365/Teams/Windows 7-10/Pulse (VPN)/internal application suite) <br></br><br></br>
                                Hardware Requests and Interventions Management (Desktops/Laptops/Enterprise Smartphones/IP Phones (Alcatel)/Surfaces Pro/VM/Printers) <br></br><br></br>
                                On-site and remote interventions (remote access via IVANTI) <br></br><br></br>
                                Management of multi-site User arrivals
                            </p>
                        </div>
                    </>
                    :
                    <>
                        <div className="BlocExperience">
                            <h3>2019</h3>
                            <p>Stage de fin de formation en tant que technicien système, Support utilisateur - GROUPE HOSPITALIER PAUL GUIRAUD - PARIS</p>
                            <p>
                                Tâche: <br></br><br></br>
                                Gestion des tickets utilisateur (ISILOG) <br></br><br></br>
                                Configuration de PC et d'imprimantes <br></br><br></br>
                                Tâches d'administration Windows, notamment la création de comptes AD/Exchange
                            </p>
                        </div>
                        <div className="BlocExperience">
                            <h3>2019/2020</h3>
                            <p>Apprenti administrateur de systèmes et ingénieur sécurité, option Cybersécurité - THALES SERVICE - PARIS</p>
                            <p>
                                Tâche: <br></br><br></br>
                                Étude de l'installation de SCCM dans un environnement de test de cybersécurité au sein du LAB (espace d'expérimentation en cybersécurité de Thales)<br></br><br></br>
                                Tâches d'administration Windows, notamment l'installation de serveurs avec des services PXE/DHCP/MDT/ADK <br></br><br></br>
                            </p>
                        </div>
                        <div className="BlocExperience">
                            <h3>2020/2022</h3>
                            <p>Technicien de support système, niveau 2 / VIP - DCS EASYWARE - Client AGIRC-ARRCO </p>
                            <p>
                                Tâche: <br></br><br></br>
                                Master PC Windows à grande échelle<br></br><br></br>
                                Participation au projet de migration vers Windows 10 <br></br><br></br>
                                Gestion des incidents utilisateur (GEDI/ITOP)<br></br><br></br>
                                Gestion des actifs informatiques (EasyVista)<br></br><br></br>
                                Gestion des incidents logiciels (365/Teams/Windows 7-10/Pulse (VPN)/suite d'applications internes) <br></br><br></br>
                                Gestion des demandes et interventions matérielles (ordinateurs de bureau/ordinateurs portables/smartphones d'entreprise/téléphones IP (Alcatel)/Surfaces Pro/VM/imprimantes) <br></br><br></br>
                                Interventions sur site et à distance (accès à distance via IVANTI) <br></br><br></br>
                                Gestion des arrivées d'utilisateurs multi-sites
                            </p>
                        </div>
                    </>

                }
            </div>
        </div>
    )


}

export default Experience


