
import Terminal from '../component/terminal'
import { useState } from 'react';
import Password from '../component/password';
import Game from '../component/game';
import { motion } from "framer-motion"






function Dev() {
    const [valid, setValid] = useState(false)
    const [click, setClick] = useState(true)

    // Return Page Dev component with first Section and generate Features presentation
    return (

        <motion.div
            initial={{ x: '-50vh' }}
            animate={{
                x: '0',
            }}
            transition={{ duration: 2 }}>
            {click ? <Terminal setClick={setClick} background={click} version="dev" />
                :
                <Terminal setClick={setClick} background={click} version="dev" />
            }
            {!valid ? <Password setValid={setValid} />
                :
                <Game />
            }

        </motion.div>

    )


}

export default Dev
