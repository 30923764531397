import { useState } from 'react';
import styled from 'styled-components'
import { keyframes } from 'styled-components'
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { motion } from "framer-motion"
import { AnimatePresence } from 'framer-motion'





const ChoiceContainer = styled(motion.div)` 
position: fixed;
top: 0;
right: 0;
bottom: 0;
left: 0;
z-index: 101;
  .buttonContainers {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    gap: 100px;
}
  .buttonContainer {
    height: 90px;
    width: 230px;
    border-radius: 100px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 60px 40px -7px;
  }
  
  .Link {
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  
  .red {
    background: radial-gradient(rgb(200, 19, 10, 0.9), rgb(200, 19, 10, 0.2)) ;
    border-color: rgb(200, 19, 10, 0.1);
    color: white;
    font-weight: bold;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 18px;
  }
  .blue {
    background: radial-gradient(rgb(23, 150, 195, 0.9), rgb(23, 150, 195, 0.2));
    border-color: rgb(23, 150, 195, 0.1);
    color: white;
    font-family: 'Alkatra', cursive;
    font-size: 18px;
  }
 
  }

`


const Choice = () => {
  const [selectedButton, setSelectedButton] = useState(null);


  const nav = useNavigate()



  const handleDevClick = (buttonId) => {
    setSelectedButton(buttonId)

    setTimeout(() => {
      nav(`${buttonId}`)
    }, 600);
  }

  // Return Page Home component with first Section and generate Features presentation
  return (

    <ChoiceContainer exit={{ x: '-200px' }} transition={{ duration: 2 }}>
      <div className='buttonContainers'>

        <motion.div className='buttonContainer'
          key='recruiter'
          initial={{ x: '-100vh', opacity: 0 }}
          animate={selectedButton === 'dev' ? { opacity: 0, x: '-300vh' } : { opacity: 1, x: '0' }}
          whileHover={{ scale: 1.1 }}
          transition={{ duration: 1.5 }}
        >
          <button className='blue Link' onClick={() => handleDevClick('recruiter')}>RECRUITER</button>
        </motion.div>
        <motion.div className='buttonContainer'
          key='dev'
          initial={{ x: '100vh', opacity: 0 }}
          animate={selectedButton === 'recruiter' ? { opacity: 0, x: '300vh' } : { opacity: 1, x: 0 }}
          transition={{ duration: 1.5 }}
          whileHover={{ scale: 1.2 }}
        >
          <button className='red Link' onClick={() => handleDevClick('dev')}>DEVELOPER</button>
        </motion.div>
      </div>
    </ChoiceContainer>

  )


}

export default Choice