import { useParams } from "react-router-dom"


function About() {

    const { language } = useParams()
    // Return Page Dev component with first Section and generate Features presentation


    return (

        <div className="containerPresentation">
            {language === "ENG" ?
                <><h2>About me</h2><p>I am Jean-Baptiste Guerin, I live in France. <br></br> After a few years searching for my path, I started with a training focused on systems (Windows/Linux), and then continued with a second training in system administration in apprenticeship with Thales. <br></br> However, this did not fully suit me, so I decided to turn my passion for development into a profession by orienting myself towards JavaScript, which I already had some knowledge of. <br></br> I followed a training on OpenClassrooms, the first European online training platform, as an <span>Application Developer - JavaScript React.</span> </p></>
                :
                <><h2>A propos de moi</h2><p>Je suis Jean-Baptiste Guerin, je vis en France. <br></br>Après quelques années à chercher ma voie, j'ai commencé une formation axée sur les systèmes (Windows/Linux), puis j'ai poursuivi avec une seconde formation en administration système en apprentissage chez Thales. Cependant, cela ne me convenait pas complètement, j'ai donc décidé de transformer ma passion pour le développement en profession en m'orientant vers JavaScript, dont j'avais déjà quelques connaissances. J'ai suivi une formation sur OpenClassrooms, la première plateforme de formation en ligne européenne, en tant que<span>  développeur d'applications - JavaScript React.</span> </p></>
            }

        </div>
    )


}

export default About


