import { useState } from 'react';
import styled from 'styled-components'


const Passwords = styled.div`
    position: absolute;
    left: 40%;
    top: 45%;
    background-color: rgb(192,192,192, 0.6);
    border-radius: 10px;
    @media (max-width: 600px) {
        left: 10%;
      }
    input {
        font: small-caption;
        font-size: 20px;
        margin: 40px;
    }
    .icon {
        position:absolute;
        left: 12px;
        top: 39%;
        font-size: 22px;
    }
`

function Password({ setValid }) {

    const [password, setPassword] = useState("")




    const handleLogin = (event) => {
        if (event.key === 'Enter') {
            if (!password || password.toLocaleLowerCase() !== 'turing') {
                return
            }
            setValid(prev => !prev)
        }

    }
    return (
        <Passwords>
            <input type="password" onChange={(e) => setPassword(e.target.value)} onKeyDown={handleLogin}></input><i className="fa-solid fa-lock icon"></i>
        </Passwords>
    )


}

export default Password