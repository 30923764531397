import { useParams } from "react-router-dom"


function Passion() {

    const { language } = useParams()
    // Return Page Dev component with first Section and generate Features presentation
    return (

        <div className="containerPassion" id="containerPassion">
            <h2>Passions</h2>
            {language === "ENG"
                ?
                <p className="textPassion">
                    I am passionate about a number of things: <br></br><br></br>
                    - Linux (Debian),<br></br><br></br>
                    - Open Source,<br></br><br></br>
                    - Hacking (Kali),<br></br><br></br>
                    - Scripting (Bash/Powershell),<br></br><br></br>
                    - Development (Javascript/React),<br></br><br></br>
                    - Blockchain Technology (Solidity/Web3.js/Biconomy/Moralis.io/ether.Js) and Cryptocurrency,<br></br><br></br>
                    - 3D Printing,<br></br><br></br>
                    - Experimentation (Unity3D/Three.js/Phaser.io),<br></br><br></br>
                    - Movies,<br></br><br></br>
                    - Gaming (MMORPG/Strategy),<br></br><br></br>
                </p>
                :
                <p className="textPassion">
                    Je m'intéresse à plusieurs domaines : <br></br><br></br>
                    - Linux (Debian),<br></br><br></br>
                    - Open Source,<br></br><br></br>
                    - Hacking (Kali),<br></br><br></br>
                    - Scripting (Bash/Powershell),<br></br><br></br>
                    - Développement (Javascript/React),<br></br><br></br>
                    - La technologie de la blockchain (Solidity/Web3.js/Biconomy/Moralis.io/ether.Js) et des cryptos,<br></br><br></br>
                    - L'impression 3D,<br></br><br></br>
                    - Experimentation (Unity3D/Three.js/Phaser.io),<br></br><br></br>
                    - Le cinéma,<br></br><br></br>
                    - Les jeux vidéos (MMORPG/Strategy),<br></br><br></br>
                </p>
            }

        </div>
    )


}

export default Passion


