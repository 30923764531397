import styled from 'styled-components'


const PagesContainer = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: scroll;
    font-size: 17px;
    @media (max-width: 900px) {
        font-size: 15px;
    };
    .headers {
        background-image: url('/background.jpg');
        background-size: cover;
        color: white;
        padding-bottom: 100px;
        padding-top: 200px;
        @media (max-width: 900px) {
            font-size: 15px
          }
    }
    .nav {
        height: 100px;
        max-width: 1480px;
        width: 100%;
        display: flex;
        align-items: center;
        overflow: hidden;
        z-index: 1000;
        color: white;
        justify-content: space-between;
        position: fixed;
        background: rgb(0, 0, 255, 0.07);
        text-shadow: black 0.1em 0.1em 0.2em;
        @media (max-width: 1200px) {
            width: 97%;
          }
    }
    .icone {
        border-radius: 50%;
        height: 70px;
        width: 70px;
        margin-left: 100px;
        background-position:center;
        background-repeat:no-repeat;
        background-size:cover;
        background-image: url('/matrix.jpg');
        &:hover {
           background-image: url('/picmix.gif');
           
        }
        @media (max-width: 900px) {
            display: none;
          }
    }
    .containerLink {
        display: flex;
        margin-right: 100px;
        gap: 40px;
        @media (max-width: 900px) {
            margin: auto;
          }
        @media (max-width: 600px) {
            gap: 15px;
        }
    }
    .links {
        color: white;
        cursor: pointer;
        &:hover {
            scale: 1.1;
        }
    }
    h1, h2 {
        text-align: center
    }
    span {
        color: rgb(65,105,225);
        font-size: 20px;
    }
    .containerPresentation {
        display: flex;
        flex-direction: column;
        margin: auto;
        width: 900px;
        font-size: 18px;
        padding-bottom: 70px;
        padding-top: 70px;
        @media (max-width: 1200px) {
            width: 700px;
            padding: 20px;
          }
          @media (max-width: 900px) {
            width: 550px;
          }
          @media (max-width: 600px) {
            width: 350px;
            padding: 10px;
          }
    }
    .containerPassion {
        display: flex;
        flex-direction: column;
        background-image: url('/background2.jpg');
        width: 100%;
        background-size: cover;
        color: rgb(255, 255, 255);
        padding-bottom: 50px;
        padding-top: 50px;
    }
    .textPassion {
        text-align: left;
        font-weight: 600;
        margin: auto;
        text-shadow: black 0.1em 0.1em 0.2em;
        @media (max-width: 900px) {
           text-align: center;
        }
    }
    .containerSkill {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        
    }
    .titleSkill {
        width:100%;
        
    }
    .blocSkill {
        width: 45%;
        background-image: url('/background3.jpg');
        background-size: cover;
        color: white;
        margin: 15px;
        display: flex;
        flex-direction: column;
        border-radius: 15px;
        overflow: hidden;
        word-break: break-all;
        h3 {
            text-align: center;
        }
        @media (max-width: 900px) {
            width: 550px;
        }
       
    }
    .textSkill {
        margin-left: 100px;
        text-shadow: black 0.1em 0.1em 0.2em;
        @media (max-width: 600px) {
            margin-left: 15px;
        }
    }
    .containerFormation {
        background-image: url('/background4.jpg');
        display: flex;
        flex-direction: column;
        color: white;
        background-size: cover;
        text-shadow: black 0.1em 0.1em 0.2em;
        
    }
    .containerMulti {
        display: flex;
        justify-content: space-around;
        padding-bottom: 60px;
        @media (max-width: 900px) {
            flex-direction: column;
            width: 100%;
          }
    }
    .BlocFormation {
        background: rgb(135,206,250, 0.5);
        width: 250px;
        display:flex;
        flex-direction: column;
        padding: 20px;
        align-items: center;
        border-radius: 10px;
        @media (max-width: 1200px) {
            width: 220px;
          }
        @media (max-width: 900px) {
            width: 70%;
            margin: auto;
            margin-bottom: 40px;
          }
    }
   
    .BlocExperience {
        background-image: url('/background5.jpg');
        background-size: cover;
        width: 380px;
        display:flex;
        flex-direction: column;
        padding: 20px;
        align-items: center;
        border-radius: 10px;
        color: white;
        text-align: center;
        text-shadow: black 0.1em 0.1em 0.2em;
        @media (max-width: 1200px) {
            width: 220px;
          }
          @media (max-width: 900px) {
            width: 70%;
            margin: auto;
            margin-bottom: 40px;
          }
    }
    .containerAll {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }
    .containerProject {
        background-image: url('/background6.jpg');
        background-size: cover;
        color: white;
        padding-bottom: 100px;
        
    }
    .BlocProject {
        text-shadow: black 0.1em 0.1em 0.2em;
        width: 30%;
        background: rgba(135, 206, 250, 0.4);
        border-radius: 10px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding-bottom: 20px;
        justify-content: center;
        margin: 12px;
        @media (max-width: 600px) {
            width: 80%;
        }
    }
    .front {
        margin: auto;
        margin-top: 12px;
    }
    .containerContact {
       background-image: url('/contact2.jpg');
       background-size: cover;
       width: 1350px;
       margin: auto;
       margin-top: 20px;
       margin-bottom: 20px;
       padding: 30px;
       color: white;
       text-shadow: black 0.1em 0.1em 0.2em;
       border-radius: 15px;
       display: flex;
       flex-direction: column;
       align-items: center;
       @media (max-width: 1200px) {
        width: 600px;
      }
      @media (max-width: 900px) {
        width: 450px;
      }
      @media (max-width: 600px) {
        width: 300px;
    }
    }
    .email {
        display: flex;
        width: 380px;
        margin: 0px;
    }
    
`
export default PagesContainer