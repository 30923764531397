import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Page from './page'
import { useParams } from 'react-router-dom'


const NavigateurContainer = styled.div`
background-color: white;
max-width: 1500px;
width: 100%;
height:  800px;
display: flex;
flex-direction: column;
margin: auto;
margin-top: 5%;
box-shadow: 10px 10px 40px rgba(0,0,0, 0.6);
@media (max-width: 900px) {
    font-size: 13px;
  }
    .header {
        height: 10%;
        border-bottom: solid 1px rgb(169,169,169);
        display: flex;
        flex-direction: column;
        color: black;
    }
    .containerIcon {
        display: flex;
        gap: 25px;
        font-size: 14px;
        justify-content: flex-end;
        margin: 10px;
        margin-left: 0px;
    }
    .searchBar {
        background: rgb(169,169,169, 0.2);
        height: 75px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 5px;
        @media (max-width: 1200px) {
            font-size: 14px;
          }
        @media (max-width: 900px) {
            font-size: 13px;
          }
    }
    .search {
        height: 85%;
        width: 90%;
        background: white;
        border: 1px solid rgb(169,169,169);
        display: flex;
        align-items: center;
        padding-left: 5px;
        padding-right: 5px;
        border-radius: 5px;
    }
    .url {
        margin: 0;
        width: 100%;
        padding-left: 5px;
    }
    .containerInfo {
        height: 160%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 150px;
        gap: 10px;
        margin-right: auto;
        background: rgb(169,169,169, 0.2);
        padding-left: 10px;
        border-radius: 0 10px 0 0;
        p {
            margin: 0;
        }
        i {
            margin: auto;
            margin-left: 50px;
            margin-bottom: 3px;
        }
    }
   
`


function Navigateur() {

    const { language } = useParams()


    return (
        <NavigateurContainer >
            <div className='header'>
                <div className='containerIcon'>
                    <div className='containerInfo'>
                        <img src="/favicon-16x16.png"></img>
                        <p>Gogle⁹</p>
                        <i className="fa-solid fa-xmark"></i>
                    </div>
                    <i className="fa-solid fa-minus"></i>
                    <i className="fa-regular fa-square"></i>
                    <Link to={`/${language}`} className='links'><i className="fa-solid fa-xmark"></i></Link>
                </div>
                <div className="searchBar">
                    <i className="fa-solid fa-arrow-left"></i>
                    <i className="fa-solid fa-arrow-right"></i>
                    <i className="fa-solid fa-arrow-rotate-right"></i>
                    <div className='search'><i className="fa-solid fa-lock"></i><Link className='url links' target="_blank" to='https://fr.wikipedia.org/wiki/Le_Guide_du_voyageur_galactique'>https://www.hamham.fr</Link><i className="fa-regular fa-star"></i></div>
                    <i className="fa-solid fa-bars"></i>
                </div>
            </div>
            <Page />
        </NavigateurContainer>
    )


}

export default Navigateur