import { useState } from 'react'
import styled from 'styled-components'

const ContainerSelect = styled.div`
    z-index: 1100;
    position: fixed;
    right: 60px;
    top:20px;
`

function Language({ onLanguageChange, language }) {

  const handleChange = (event) => {
    onLanguageChange(event.target.value);
  }


  return (
    <ContainerSelect>
      <select onChange={handleChange} defaultValue={language}>
        <option value="ENG">ENG</option>
        <option value="FR">FR</option>
      </select>
    </ContainerSelect>
  )


}

export default Language