import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import About from "./pageFake/about";
import Passion from "./pageFake/passion";
import Skill from "./pageFake/skill";
import Formation from "./pageFake/formation";
import Experience from "./pageFake/experience";
import Project from "./pageFake/project";
import PagesContainer from "./pageFake/style";




function Page() {
    const [data, setData] = useState("")
    const skill = document.querySelector('.containerSkill');
    const formation = document.querySelector('.containerFormation')
    const experience = document.querySelector('.containerExperience')
    const project = document.querySelector('.containerProject')
    const contact = document.querySelector('.containerContact')
    const start = document.querySelector('.headers')

    //element.scrollIntoView();
    useEffect(() => {
        if (data) {
            data?.scrollIntoView({ behavior: 'smooth' })
        }
        setData("")
    }, [data])

    const { language } = useParams()

    return (
        <PagesContainer>

            {language === "ENG" ?
                <>
                    <nav className="nav"><img className="icone" onClick={() => setData(start)}></img><div className="containerLink"><a onClick={() => setData(skill)} className="links">Skill</a><a onClick={() => setData(formation)} className="links">Formation</a><a className="links" onClick={() => setData(experience)}>Experience</a><a className="links" onClick={() => setData(project)}>Projects</a><a className="links" onClick={() => setData(contact)} >Contact</a></div></nav>
                    <header className="headers">
                        <h1>Jean-Baptiste Guerin</h1>
                        <h2>Junior javascript developer</h2>
                    </header>
                </>
                :
                <>
                    <nav className="nav"><img className="icone" onClick={() => setData(start)}></img><div className="containerLink"><a onClick={() => setData(skill)} className="links">Compétences</a><a onClick={() => setData(formation)} className="links">Formations</a><a className="links" onClick={() => setData(experience)}>Experience</a><a className="links" onClick={() => setData(project)}>Projets</a><a className="links" onClick={() => setData(contact)} >Contacts</a></div></nav>
                    <header className="headers">
                        <h1>Jean-Baptiste Guerin</h1>
                        <h2>Développeur javascript junior</h2>
                    </header>
                </>
            }

            <About></About>
            <Passion></Passion>
            <Skill></Skill>
            <Formation></Formation>
            <Experience></Experience>
            <Project></Project>
            {language === "ENG" ?
                <div className="containerContact">
                    <h2>Contact</h2>
                    <p>You can reach me by email or through my social media channels</p>
                    <p className="email">Mail: &nbsp; <Link className="links" to="mailto:jb.l.guerin@gmail.com">jb.l.guerin@gmail.com</Link></p>
                    <p>Social Network: <br></br><br></br><i className="fa-brands fa-linkedin"></i>&nbsp; <Link target="_blank" to='https://www.linkedin.com/in/jean-baptiste-guerin/' className="links">https://www.linkedin.com/in/jean-baptiste-guerin/ </Link>
                        <br></br><br></br><i className="fa-brands fa-github"></i>&nbsp; <Link target="_blank" to='https://github.com/Sharkoux?tab=repositories' className="links">https://github.com/Sharkoux?tab=repositories </Link></p>
                </div>
                :
                <div className="containerContact">
                    <h2>Contact</h2>
                    <p>Vous pouvez me contacter par mail ou via mes réseaux sociaux:</p>
                    <p className="email">Mail: &nbsp; <Link className="links" to="mailto:jb.l.guerin@gmail.com">jb.l.guerin@gmail.com</Link></p>
                    <p>Réseaux sociaux: <br></br><br></br><i className="fa-brands fa-linkedin"></i>&nbsp; <Link target="_blank" to='https://www.linkedin.com/in/jean-baptiste-guerin/' className="links">https://www.linkedin.com/in/jean-baptiste-guerin/ </Link>
                        <br></br><br></br><i className="fa-brands fa-github"></i>&nbsp; <Link target="_blank" to='https://github.com/Sharkoux?tab=repositories' className="links">https://github.com/Sharkoux?tab=repositories </Link></p>
                </div>
            }

        </PagesContainer>
    )


}

export default Page


