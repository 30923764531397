
import React from 'react'
import Route from './route/route'
import { createRoot } from 'react-dom/client'
import RouteWrapper from './component/routewrapper'


const root = createRoot(document.getElementById('root'))
root.render(
        <React.StrictMode>
            <RouteWrapper>
            <Route />
            </RouteWrapper>
        </React.StrictMode>
)