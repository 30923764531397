import { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import data from '../data/data.json'
import data2 from '../data/data2.json'
import { Link, useParams } from "react-router-dom";

const InputContainer = styled.div`
    padding-left: 40px;
    padding-top: 10px;
    color: 	rgb(211,211,211);
    display: flex;
    flex-direction: column;
    height: 20px;
    @media (max-width: 600px) {
        padding-left: 15px;
       
      }
    .input{
        border: none;
        outline: none;
        width: 800px;
        background-color:  rgb(48, 10, 36);
        color: white;
        @media (max-width: 1200px) {
            width: 600px;
          }
          @media (max-width: 900px) {
            width: 300px;
          }
          @media (max-width: 600px) {
            width: 200px;
          }
    }
    .inputs {
        display: flex;
        
    }
    .results {
        display: flex;
        font-size: 15px;
        gap: 15px;
        @media (max-width: 900px) {
            width: 500px;
            flex-direction: column;
          }
        @media (max-width: 600px) {
            width: 340px;
          }
    }
    .Links {
        text-decoration: none;
        width: 800px;
        color: rgb(154,205,50, 0.9);
    }
    .resultsTitle {
        font-size: 18px;
        font-weight: bold;
        color: white;
        @media (max-width: 900px) {
            font-size: 15px;
          }
        @media (max-width: 600px) {
            font-size: 12px;
        }
        
    }
    .error {
        font-size: 18px;
        color: red;
    }
`


function Input() {
    const [line, setLine] = useState([])
    const [search, setSearch] = useState("")
    const el = useRef(null)
    let dataValues

    const COMMANDS = [{ name: 'help', index: 0, }, { name: 'who', index: 1, }, { name: 'from', index: 2, }, { name: 'exp', index: 3, }, { name: 'skill', index: 4, }, { name: 'contact', index: 5, }, { name: 'project', index: 6, }, { name: 'terminal', index: 7, },];

    const { language } = useParams()


    useEffect(() => {
        el.current.scrollIntoView({ block: 'end', behavior: 'smooth' })
    }, [line])


    const handleInput = (event) => {
        if (event.key === 'Enter') {
            // Split the user's input into words and compare the first word to the list of valid commands
            const words = search.trim().split(/\s+/);
            const command = words[0].toLowerCase();
            const commandIndex = COMMANDS.findIndex(c => c.name === command);
            if (!search) {
                setLine(line => [...line, <p>hamham@hamham:~$ &gt; </p>])
            } else {
                if (commandIndex === -1) {
                    // If the command is not recognized, display an error message
                    setLine(line => [...line, <p className='error'>Command not found: {command}</p>]);
                } else {
                    // If the command is valid, display the corresponding data
                    if (language === 'ENG') {
                        dataValues = data[COMMANDS[commandIndex].index]?.value;
                    }
                    if (language === 'FR') {
                        dataValues = data2[COMMANDS[commandIndex].index]?.value;
                    }
                    if (dataValues) {
                        dataValues.forEach((item) => {
                            if (item.includes('https')) {
                                // If the item contains a link, display it as a clickable link
                                const lien = item.substring(item.indexOf('https'));
                                const ex = item.replace(lien, '');
                                setLine(line => [...line, <p className='results'>{ex} <Link target="_blank" className='Links' to={lien}>{lien}</Link></p>]);
                            } else {
                                if (item === item.toLocaleUpperCase()) {
                                    setLine(line => [...line, <p className='resultsTitle'>{item}</p>])
                                }
                                else {
                                    // Otherwise, just display the item as text
                                    setLine(line => [...line, <p className='results'>{item}</p>]);
                                }

                            }
                        });
                    }
                }
            }
            // Clear the search input after each command
            setSearch("");
        }
    };



    // Return Page Input component with first Section and generate Features presentation
    return (

        <InputContainer>
            {line.length > 0 ? line : ''}
            <p className='resultsTitle'> -------------------------------------------------------------------------------------------------</p>
            <div className='inputs' ref={el}>
                <p>hamham@hamham:~$ &gt; </p><input className='input' type="search" autoFocus onKeyDown={handleInput} onChange={(e) => setSearch(e.target.value)}></input>
            </div>
        </InputContainer>
    )


}

export default Input
