import styled from 'styled-components'

const GameContainer = styled.div`
position: absolute;
left: 30%;
top: 11%;
@media (max-width: 1200px) {
    left: 15%;
  }
@media (max-width: 968px) {
    left: 15%;
  }

  iframe {
    @media (max-width: 1200px) {
        width: 800px;
      }
    @media (max-width: 968px) {
        width: 600px;
      }
     @media (max-width: 600px) {
        width: 400px;
      }
  }
`




function Game() {


  return (
    <GameContainer>
      <iframe width="900" height="650" frameborder="0" allowfullscreen="" src="https://dos.zone/player/?bundleUrl=https%3A%2F%2Fcdn.dos.zone%2Fcustom%2Fdos%2Fwarcraft.jsdos?anonymous=1"></iframe>
    </GameContainer>
  )


}

export default Game