
import { AnimatePresence } from 'framer-motion';


function RouteWrapper({ children }) {
    return (
      <AnimatePresence mode="wait">
        {children}
      </AnimatePresence>
    );
  }


  export default RouteWrapper