import { useParams } from "react-router-dom"


function Formation() {

    const { language } = useParams()
    // Return Page Dev component with first Section and generate Features presentation
    return (
        <div className="containerFormation">
            <h2>Formations</h2>
            <div className="containerMulti">
                {language === "ENG" ?
                    <>
                        <div className="BlocFormation">
                            <h2>2019</h2>
                            <h3>TSSR</h3>
                            <p>Higher Technician in Systems and Networks, RNCP Diploma (Bac+2), WebForce3, Paris (2019). Covered Windows/Linux infrastructure and Cisco networking.</p>
                        </div>
                        <div className="BlocFormation">
                            <h2>2019 / 2020</h2>
                            <p>Apprenticeship as a Systems Administrator with a focus on Cybersecurity, RNCP Diploma (Bac+4), AFTI, THALES SERVICES, Paris (2020). Stopped due to personal reasons.</p>
                        </div>
                        <div className="BlocFormation">
                            <h2>2022/2023</h2>
                            <h3>Javascript Application Developer</h3>
                            <p>OpenClassroom, Javascript Application Developer with React, RNCP Diploma (Bac+4), expected to finish in August 2023.</p>
                        </div>
                    </>
                    :
                    <>
                        <div className="BlocFormation">
                            <h2>2019</h2>
                            <h3>TSSR</h3>
                            <p>Technicien Supérieur Systèmes et Réseaux, diplome RNCP (Bac+2), WebForce3, Paris (2019). Couvre l'infrastructure Windows/Linux et le réseau Cisco.</p>
                        </div>
                        <div className="BlocFormation">
                            <h2>2019 / 2020</h2>
                            <p>Apprentissage en tant qu'administrateur de systèmes axé sur la cybersécurité, Diplôme RNCP (Bac+4), AFTI, THALES SERVICES, Paris (2020). Arrêté pour des raisons personnelles.</p>
                        </div>
                        <div className="BlocFormation">
                            <h2>2022/2023</h2>
                            <h3>Développeur d'application Javascript</h3>
                            <p>OpenClassroom, Développeur d'applications Javascript avec React, Diplôme RNCP (Bac+4), prévu pour août 2023.</p>
                        </div>
                    </>
                }
            </div>
        </div>
    )


}

export default Formation


